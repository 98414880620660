import * as React from "react"
import style from "./image.module.scss"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const GatsbyImage = ({ name, alt, className, ...rest }) => (
  <StaticQuery
    query={graphql`
      query AllImages {
        allImagesWithoutSVGExtension: allFile(
          filter: {
            sourceInstanceName: { eq: "images" }
            extension: { regex: "/jpeg|jpg|png|gif/" }
          }
        ) {
          nodes {
            publicURL
            extension
            sharp: childImageSharp {
              fluid {
                originalName
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        allImagesWithSVGExtension: allFile(
          filter: {
            sourceInstanceName: { eq: "images" }
            extension: { eq: "svg" }
          }
        ) {
          nodes {
            publicURL
            extension
          }
        }
      }
    `}
    render={({ allImagesWithoutSVGExtension, allImagesWithSVGExtension }) => {
      const isNameWithSVGExtension = name.indexOf("svg") !== -1
      const renderImageWithSVGExtension = () => {
        const image = allImagesWithSVGExtension.nodes.find(
          ({ publicURL }) => publicURL && publicURL.indexOf(name) !== -1
        )
        return image ? (
          <img
            {...rest}
            className={`${style.image} ${className}`}
            src={image.publicURL}
            alt={alt}
          />
        ) : null
      }
      const renderImageWithoutSVGExtension = () => {
        const image = allImagesWithoutSVGExtension.nodes.find(
          ({ publicURL }) => publicURL && publicURL.indexOf(name) !== -1
        )
        return image && image.sharp && image.sharp.fluid ? (
          <Img
            {...rest}
            className={`${style.image} ${className}`}
            fluid={image.sharp.fluid}
            alt={alt}
          />
        ) : null
      }

      return isNameWithSVGExtension
        ? renderImageWithSVGExtension()
        : renderImageWithoutSVGExtension()
    }}
  />
)
export { GatsbyImage }
